import React from 'react'
import languageService from '../../services/language-service'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { findKey, chain } from 'lodash'


const LanguageSwitcher = () => {
  const currentLanguage = findKey(languageService.LANGUAGES, (o) => o === languageService.getLanguage())

  return (
    <UncontrolledDropdown>
      <DropdownToggle nav caret className='navbar-element text-medium'>
        {currentLanguage}
      </DropdownToggle>
      <DropdownMenu className='dropdown-menu'>
        {chain(languageService.LANGUAGES)
          .keys()
          .filter((key) => key !== currentLanguage)
          .map(createMenuItem)
          .value()
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

const createMenuItem = (value) => (
  <DropdownItem
    key={value}
    className={'dropdown-item text-medium bold navbar-element'}
    onClick={() => languageService.changeLanguage(value)}
  >
    {value}
  </DropdownItem>
)


export default LanguageSwitcher
