import React from 'react'


const FbLogo = () => (
  <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg">

    <g>
      <title>background</title>
      <rect fill="none" id="canvas_background" height="30" width="30" y="-1" x="-1"/>
    </g>
    <g>
      <title>Layer 1</title>
      <path stroke="null" id="svg_1"  fill="var(--textPurple)" d="m12.203251,19.736463l2.459348,0l0,-5.952176l1.640659,0l0.217345,-2.050915l-1.858004,0l0.002553,-1.026916c0,-0.534609 0.051054,-0.821606 0.818324,-0.821606l1.025458,0l0,-2.05128l-1.641024,0c-1.971052,0 -2.664658,0.99519 -2.664658,2.668305l0,1.231497l-1.22858,0l0,2.05128l1.22858,0l0,5.951812zm1.544386,4.988713c-6.041886,0 -10.94016,-4.898274 -10.94016,-10.94016c0,-6.04225 4.898274,-10.94016 10.94016,-10.94016s10.94016,4.89791 10.94016,10.94016c0,6.041886 -4.898274,10.94016 -10.94016,10.94016z"/>
    </g>
  </svg>
)

export default FbLogo