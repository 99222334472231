import React from 'react'


const NopeLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="84" height="17" viewBox="0 0 84 17">
    <g fill="#1B1B1B">
      <path
        d="M55.3308902,11.8753905 L52.2174092,11.8753905 L52.2174092,17 L48,17 L48,0 L55.3308902,0 C57.3571605,0 58.9758709,0.534443961 60.1858685,1.6027609 C61.394713,2.67164881 62,4.09569074 62,5.8771706 C62,7.65807949 61.387219,9.10324793 60.1610805,10.212105 C58.934942,11.3215329 57.3248786,11.8753905 55.3308902,11.8753905 M57.1087046,7.7106103 C57.5577699,7.24925269 57.7825908,6.63829646 57.7825908,5.8771706 C57.7825908,5.11604475 57.5577699,4.52507307 57.1087046,4.10425555 C56.6590629,3.68343803 55.9846002,3.47274378 55.0858931,3.47274378 L52.2174092,3.47274378 L52.2174092,8.40264672 L55.0858931,8.40264672 C55.9846002,8.40264672 56.6590629,8.17196791 57.1087046,7.7106103" />
      <polygon
        points="35.215 15 31.499 11.281 27.78 14.997 25 12.215 28.719 8.499 25.003 4.78 27.785 2 31.501 5.719 35.22 2.003 38 4.785 34.281 8.501 37.997 12.22" />
      <polygon
        points="71 17 71 0 83.974 0 83.974 3.473 75.402 3.473 75.402 6.655 83.206 6.655 83.206 10.127 75.402 10.127 75.402 13.527 84 13.527 84 17" />
      <polygon points="10.758 0 10.758 9.933 4.116 0 0 0 0 17 4.242 17 4.242 7.068 10.859 17 15 17 15 0" />
    </g>
  </svg>
)

export default NopeLogo
