import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Container, Navbar, NavbarToggler } from 'reactstrap'
import NavBarLogo from './navbarLogo'
import NavBarMenu from './navbarMenu'
import Burger from './burger'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../styles/common.css'
import './navbar.css'


const query = graphql`
  query MenuQuery {
    allWordpressWpApiMenusMenusItems {
      edges {
        node {
          id
          name
          items {
            object_id
            title
            object_slug
            description
            wordpress_children {
              object_id
              title
              object_slug
              description
            }
          }
        }
      }
    }
    allWordpressWpMedia {
      edges {
        node {
          id
          title
          source_url
          localFile {
            id
            absolutePath
          }
        }
      }
    }
  }
`

export default class NavBar extends Component {
  constructor (props) {
    super(props)

    this.state = { isOpen: false }
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })

  render () {
    return (
      <StaticQuery
        query={query}
        render={data => (
          <div className={`nav-bar ${this.state.isOpen ? 'nav-bar-open': ''}`}>
            <Navbar className={`pink-background ${this.state.isOpen ? 'nav-bar-open': ''}`} light expand='lg'>
              <Container fluid className='nav-bar-container' >
                <NavBarLogo data={data} />
                <NavbarToggler onClick={this.toggle}>
                  <Burger isOpen={this.state.isOpen}/>
                </NavbarToggler>
                <NavBarMenu data={data} isOpen={this.state.isOpen} toggle={this.toggle} />
              </Container>
            </Navbar>
          </div>
        )}
      />
    )
  }
}
