import React from 'react'
import LanguageService from '../../services/language-service'


const getText = (language, isOpen) => language && (isOpen
  ? ({ et: 'Sulge', en_US: 'Close', ru_RU: 'Закрыть' }[language])
  : ({ et: 'Menüü', en_US: 'Menu', ru_RU: 'Меню' }[language])
)

const Burger = ({ isOpen }) => (
  <div className='burger'>
    {isOpen
      ? <div className='burger-stripe-box'><div className='navbar-close'/></div>
      : <div className='burger-stripe-box'>
          <div className='burger-stripe' />
          <div className='burger-stripe' />
        </div>
    }
    <div className='burger-stripe-box'>
      {getText(LanguageService.getLanguage(), isOpen)}
    </div>
  </div>
)

export default Burger
