import { navigate } from 'gatsby'

const LANGUAGES = {
  ET: 'et',
  RU: 'ru_RU'
}

const changeLanguage = (lang) => {
  setLanguage(LANGUAGES[lang])

  if (window.location.pathname === '/uudised') {
    navigate('/uudised')
  } else if (window.location.pathname === '/tugigrupid') {
    navigate('/tugigrupid')
  } else {
    navigate('/')
  }
}

function getLanguage() {
  if (typeof window === 'undefined') {
    return LANGUAGES.ET;
  }

  let language = localStorage.getItem('lang');
  if (language === null) {
    localStorage.setItem('lang', LANGUAGES.ET);
    return LANGUAGES.ET;
  }
  return language;
}

function setLanguage(language) {
  if (window !== 'undefined') {
    localStorage.setItem('lang', language);
  }
}

function getEventsFeedTitle() {
  return EventsFeed[this.getLanguage()];
}

function getSeeAllEventsLabel() {
  return SeeAllEvents[this.getLanguage()];
}

function getSupportGroupsTitle() {
  return SupportGroups[this.getLanguage()];
}

function getAllSupportGroupsLabel() {
  return SeeAllSupportGroups[this.getLanguage()];
}

function getPageNotFoundMessage() {
  return PageNotFound[this.getLanguage()];
}

function getMonthString(monthNumber) {
  return Month[this.getLanguage()][monthNumber];
}

function getDescriptionMissingMessage() {
  return DescriptionMissing[this.getLanguage()];
}

function getHomePage() {
  return HomePage[this.getLanguage()];
}

function getPostMoreInfo() {
  return PostMoreInfo[this.getLanguage()];
}

function getVideoTitle() {
  return VideoTitle[this.getLanguage()];
}

function getVideoDescription() {
  return VideoDescription[this.getLanguage()];
}

function getSupportTitle() {
  return SupportTitle[this.getLanguage()];
}

function getSupportText() {
  return SupportText[this.getLanguage()];
}

function getPartnersTitle() {
  return PartnersTitle[this.getLanguage()];
}

function getPartnersText() {
  return PartnersText[this.getLanguage()];
}

function getFilterArchiveTitle() {
  return FilterArchiveTitle[this.getLanguage()];
}

function getEventsArchiveTitle() {
  return EventsArchiveTitle[this.getLanguage()];
}

function getSupportgroupsArchiveTitle() {
  return SupportgroupsArchiveTitle[this.getLanguage()];
}

function getFooterSupportText() {
  return FooterSupportText[this.getLanguage()];
}

function getFooterPhone() {
  return FooterPhone[this.getLanguage()];
}

function getFooterEmail() {
  return FooterEmail[this.getLanguage()];
}

function getVideoBtnText() {
  return VideoBtnText[this.getLanguage()];
}

function getSupportButton() {
  return SupportButton[this.getLanguage()];
}

export default {
  getLanguage,
  setLanguage,
  getEventsFeedTitle,
  getSeeAllEventsLabel,
  getSupportGroupsTitle,
  getAllSupportGroupsLabel,
  getPageNotFoundMessage,
  getMonthString,
  getDescriptionMissingMessage,
  getHomePage,
  getPostMoreInfo,
  getSupportTitle,
  getSupportText,
  getPartnersTitle,
  getPartnersText,
  getFilterArchiveTitle,
  getEventsArchiveTitle,
  getSupportgroupsArchiveTitle,
  getFooterSupportText,
  getFooterPhone,
  getFooterEmail,
  getVideoBtnText,
  getSupportButton,
  changeLanguage,
  LANGUAGES,
};

const EventsFeed = {
  et: 'Uudised',
  en_US: 'Events',
  ru_RU: 'Новости'
};

const SeeAllEvents = {
  et: 'Vaata kõiki uudiseid',
  en_US: 'See all events',
  ru_RU: 'Посмотри все новости'
};

const SupportGroups = {
  et: 'Tugigrupid',
  en_US: 'Support groups',
  ru_RU: 'Группы поддержки'
};

const SeeAllSupportGroups = {
  et: 'Vaata kõiki tugigruppe',
  en_US: 'See all support groups',
  ru_RU: 'Посмотри все группы поддержки'
};

const PageNotFound = {
  et: ['Lehekülge ei leitud', 'Mine kodulehele'],
  en_US: ['Page not found', 'Go to home page'],
  ru_RU: ['Lehekülge ei leitud RU', 'Mine kodulehele RU']
};

const Month = {
  et: [
    'jaanuar',
    'veebruar',
    'märts',
    'aprill',
    'mai',
    'juuni',
    'juuli',
    'august',
    'september',
    'oktoober',
    'november',
    'detsember'
  ],
  en_US: [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
  ],
  ru_RU: [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря'
  ]
};

const DescriptionMissing = {
  et: 'Üherealine täpsustus selle punkti kohta',
  en_US: 'One line specification about this point',
  ru_RU: 'Одна строка спецификации об этом пункте'
};

const HomePage = {
  et: 'Avaleht',
  en_US: 'Home',
  ru_RU: 'Главная'
};

const PostMoreInfo = {
  et: 'Loe rohkem',
  en_US: 'Read more',
  ru_RU: 'Читать больше'
};

const VideoTitle = {
  et: 'Mis on autism',
  en_US: 'What is autism',
  ru_RU: 'Что такое аутизм'
};

const VideoDescription = {
  et: 'Vaata filmi',
  en_US: 'Watch the video',
  ru_RU: 'Посмотреть видеопод'
};

const SupportTitle = {
  et: 'Meie tegemisi toetavad',
  en_US: 'Our supporters',
  ru_RU: 'Нас поддерживают'
};

const SupportText = {
  et: 'Meie tugigruppide toimumist toetab alates  2014 aastast annetuskeskkond „Ma Armastan aidata“.',
  en_US: 'Our supportgroups are supported by „Ma Armastan aidata“ donation since 2014',
  ru_RU: 'Начиная с 2014 года проведение групп поддержек поддерживает „Ma Armastan aidata“'
};

const PartnersTitle = {
  et: 'Meie partnerid',
  en_US: 'Our supporters',
  ru_RU: 'Наши партнёры'
};

const PartnersText = {
  et:
    'Meie toetajateks on autismispektri häiretega inimeste lähedased, sõbrad, kooli- ja töökaaslased, samuti selle valdkonna teadlased, arendajad, spetsialistid, üliõpilased, pedagoogid jt. ning ettevõtted ja organisatsioonid, kes soovivad panustada Eesti ühiskonna demokraatlikku arendamisse.',
  en_US:
    'Our supporters are the families, friends and colleagues of people with autism spectre disorders. We also have support from scientists, teachers, students, companies and other specialists, who wish to help advancing Estonias democratic society.',
  ru_RU:
    'Нашей поддержкой являются близкие людей с расстройством аутистического спектра, друзья, школьные товарищи и коллеги по работе. Также научные исследователи, специалисты, студенты, педагоги, различные предприятия и организации и многие другие. Те, кто хотят внести свой вклад в развитие нашего общества.'
};

const FilterArchiveTitle = {
  et: 'Filtreeri aasta järgi',
  en_US: 'Filter by year',
  ru_RU: 'Фильтр по годам'
};

const EventsArchiveTitle = {
  et: 'Uudiste arhiiv',
  en_US: 'Events Archive',
  ru_RU: 'Архив новостей'
};

const SupportgroupsArchiveTitle = {
  et: 'Tugigruppide arhiiv',
  en_US: 'Support groups Archive',
  ru_RU: 'Архив групп поддержки'
};

const FooterSupportText = {
  et: 'See koduleht on valminud koostöös',
  en_US: 'This site was made with the help of',
  ru_RU: 'Эта веб-страница создана при поддержке'
};

const FooterPhone = {
  et: 'Telefon',
  en_US: 'Phone',
  ru_RU: 'Телефон'
};

const FooterEmail = {
  et: 'E-post',
  en_US: 'Email',
  ru_RU: 'Эл. почта'
};

const VideoBtnText = {
  et: 'Vaata videot',
  en_US: 'Watch video',
  ru_RU: 'Смотрите фильм'
};

const SupportButton = {
  et: 'Toeta',
  en_US: 'Donate',
  ru_RU: 'Поддержи нас'
};
