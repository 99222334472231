import React from 'react'
import { Link } from 'gatsby'
import languageService from '../../services/language-service'
import LanguageSwitcher from './languageSwitcher'
import { Col, Collapse, DropdownMenu, DropdownToggle, Nav, NavItem, Row, UncontrolledDropdown } from 'reactstrap'


const menuName = {
  et: 'Peamenüü',
  en_US: 'Inglise menüü',
  ru_RU: 'Vene menüü'
}

class NavBarMenu extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      mounted: false
    }
  }

  componentDidMount () {
    this.setState({ mounted: true })
  }

  renderMenuItems = (filteredQueryData) => filteredQueryData[0].node.items
    .filter(item => !item.title.startsWith('<img'))
    .map(item => {
      if (item.wordpress_children != null) {
        return (
          <UncontrolledDropdown key={item.object_id} nav inNavbar>
            <div
              className='navbar-rectangle'
              style={{ opacity: this.state.mounted ? +isActive(item.wordpress_children) : 0 }}
            />
            <DropdownToggle nav caret className='navbar-element text-medium'>
              {item.title}
            </DropdownToggle>
            <DropdownMenu className='dropdown-menu'>{renderDropdownItems(item.wordpress_children)}</DropdownMenu>
          </UncontrolledDropdown>
        )
      } else {
        return (
          <NavItem key={item.object_id}>
            <div className='navbar-rectangle' style={{ opacity: this.state.mounted ? +isActive([item]) : 0 }} />
            <Link className='nav-link text-medium navbar-element' to={'/' + item.object_slug}>
              {item.title}
            </Link>
          </NavItem>
        )
      }
    })

  render () {
    const { data, isOpen } = this.props

    return (
      <Col xs={12} sm={12} md={12} lg={'auto'} xl={'auto'} className={'nav-bar-menu'} style={{ padding: 0 }}>
        <Collapse isOpen={isOpen} navbar>
          <Row noGutters>
            <Nav navbar>
              <NavItem onClick={(e) => e.preventDefault()}>
                <div className='navbar-rectangle' style={{ opacity: this.state.mounted ? +isActive() : 0 }} />
                <Link to={'/'} className='nav-link text-medium navbar-element'>
                  {languageService.getHomePage()}
                </Link>
              </NavItem>

              {this.renderMenuItems(selectMenuInCorrectLanguage(data))}
              <NavItem className='donate-navlink'>
                <Link className='text-medium purple-background donate-link' to='/toeta'>
                  {languageService.getSupportButton()}
                </Link>
              </NavItem>
              <NavItem className='text-medium language-switcher-container' active={false}>
                <LanguageSwitcher />
              </NavItem>
            </Nav>
          </Row>
        </Collapse>
      </Col>
    )
  }
}

const renderDropdownItems = (items) => items.map(item => (
  <Link
    key={item.object_id}
    className='dropdown-item text-medium bold navbar-element'
    activeClassName='dropdown-item-selected'
    to={'/' + item.object_slug}
  >
    <div className='menu-item-title'>{item.title}</div>
    <div className='menu-item-description'>{getDescription(item)}</div>
  </Link>
))

const isActive = (children) => {
  const currentPath = typeof window !== 'undefined' &&
    window.location.pathname &&
    window.location.pathname.replace(/\//g, '')

  if (children) {
    return children.map((o) => o.object_slug).includes(currentPath)
  }

  return currentPath === ''
}

const getDescription = (item) => item.description ? item.description : languageService.getDescriptionMissingMessage()

const selectMenuInCorrectLanguage = (queryData) => queryData
  .allWordpressWpApiMenusMenusItems
  .edges
  .filter(menu => menu.node.name === menuName[languageService.getLanguage()])

export default NavBarMenu