import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import languageService from '../../services/language-service'
import NopeLogo from '../../styles/nopeLogo'


const Credits = ({ gqlData }) => (
  <Container className='pt-4 pl-0 pr-0 m-0 mr-md-auto ml-lg-auto mr-lg-auto footer-container' >
    <Row className='credits-row mb-2'>
      <Col xs={{ size: 12, order: 1 }} lg={{ size: 2, order: 1 }} className="p-1">
        <p className="text-small">© {(new Date()).getFullYear()}</p>
      </Col>
      <Col xs={{ size: 12, order: 1 }} lg={{ size: 4, order: 2 }} className="p-1 credits-support">
        <p className="text-small">{languageService.getFooterSupportText()}</p>
      </Col>
      <Col xs={{ size: 12, order: 2 }} lg={{ size: 2, order: 3 }} className="p-1">
        <a href="http://catapultlabs.eu/" target="_blank" className="image">
          <img
            className="credits-logo"
            src={
              gqlData.allWordpressWpMedia.edges.filter(({ node }) =>
                node.source_url.includes('/2018/12/catapult-500x117.png')
              )[0].node.source_url
            }
          />
        </a>
      </Col>
      <Col xs={{ size: 12, order: 3 }} lg={{ size: 2, order: 3 }} className="p-1">
        <a href="https://www.ut.ee/et" target="_blank" className="image">
          <img
            className="credits-logo"
            src={
              gqlData.allWordpressWpMedia.edges.filter(({ node }) =>
                node.source_url.includes('TÜ_logod_17122015_horisontaal_est_sinine-300x53.png')
              )[0].node.source_url
            }
          />
        </a>
      </Col>
      <Col xs={{ size: 12, order: 3 }} lg={{ size: 2, order: 3 }} className="p-1">
        <a href="https://www.nope.ee" target="_blank" className="image">
          <NopeLogo />
        </a>
      </Col>
    </Row>
  </Container>
)

export default Credits
