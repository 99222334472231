import React from 'react'
import { Col, NavbarBrand } from 'reactstrap'


const NavBarLogo = ({ data }) => {
  const source = data.allWordpressWpMedia.edges.filter(({ node }) =>
    node.source_url.includes(
      '/2018/12/THAutismiyhing_logo_colour_light-bg_RGB.png'
    )
  )[0].node.source_url

  return (
    <Col xs={8} sm={9} md={9} lg={2} xl={2} style={{ padding: 0 }}>
      <NavbarBrand href='/' className='navbar-brand navbar-element'>
        <img className='navbar-logo' src={source} />
      </NavbarBrand>
    </Col>
  )
}

export default NavBarLogo