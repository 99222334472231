import React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import languageService from '../../services/language-service'
import { Col, Container, Row } from 'reactstrap'
import Credits from './credits'
import Logo from '../../styles/fbLogo'
import { find } from 'lodash'


const query = graphql`
  query {
    allWordpressWpWidgets {
      edges {
        node {
          id
          title
          text
        }
      }
    }
    allWordpressWpMedia {
      edges {
        node {
          id
          title
          source_url
          localFile {
            id
            absolutePath
          }
        }
      }
    }
  }
`

export default () => (
  <StaticQuery
    query={query}
    render={data => (
      <Container fluid>
        <Row className="quote-box align-content-end">
          <div className='w-100'>
            <p className="quote purple-text ml-auto">{getQuoute(getFooterContent(data))}</p>
          </div>
        </Row>
        <Row className="green-background-footer footer-box">{renderFooter(data)}</Row>
        <Row className="credits-box">
          <Credits gqlData={data} />
        </Row>
      </Container>
    )}
  />
);

function renderFooter (gqlData) {
  const footerContent = getFooterContent(gqlData)

  return (
    <div className='footer-container'>
      <Row className='footer-row'>
        <Col
          className="footer-column"
          xs={{ size: 12, order: 1 }}
          sm={{ size: 12, order: 1 }}
          md={{ size: 12, order: 1 }}
          lg={{ size: 3, order: 1 }}
        >
          <Row className="text-small bold">
            <img
              className="footer-logo mb-3"
              src={
                gqlData.allWordpressWpMedia.edges.filter(({ node }) =>
                  node.source_url.includes('/2018/12/THAutismiyhing_logo_colour_light-bg_RGB.png')
                )[0].node.source_url
              }
            />
          </Row>
          <Row className="text-small"> {getCompanyInfo(footerContent)} </Row>
        </Col>

        <Col
          className="footer-column"
          xs={{ size: 12, order: 2 }}
          sm={{ size: 12, order: 2 }}
          md={{ size: 12, order: 2 }}
          lg={{ size: 3, order: 2 }}
        >
          <Row className="text-small bold">{languageService.getFooterEmail()}</Row>
          <Row className='text-medium'>{getEmail(footerContent)}</Row>
          <Row className="text-small bold">{languageService.getFooterPhone()}</Row>
          <Row className='text-medium'>{getPhone(footerContent)}</Row>
          <Row className='pt-2'>
            <Logo className='fb-logo' />
            <a
              className="text-small bold purple-text fb-link pl-1"
              href="https://www.facebook.com/autismtallinn/"
            >
              @autismtallinn
            </a>
          </Row>
        </Col>

        <Col
          className="footer-column"
          xs={{ size: 12, order: 3 }}
          sm={{ size: 12, order: 3 }}
          md={{ size: 12, order: 3 }}
          lg={{ size: 3, order: 3 }}
        >
          {renderLinks()}
        </Col>

        <Col
          className="footer-column-last"
          xs={{ size: 12, order: 4 }}
          sm={{ size: 12, order: 4 }}
          md={{ size: 12, order: 4 }}
          lg={{ size: 3, order: 4, offset: 0 }}
        >
          <p className="text-small bold">Meid toetavad</p>
          <Row>
            <Col xs={{ size: 6, offset: 0 }} md={{ size: 3, offset: 0 }} lg={{ size: 5, offset: 0 }}>
              <a href="http://www.ngo.ee/annetamine" target="_blank" className="image">
                <img
                  className="footer-image-box"
                  src={
                    gqlData.allWordpressWpMedia.edges.filter(({ node }) =>
                      node.source_url.includes('/2014/09/koguja.png')
                    )[0].node.source_url
                  }
                />
              </a>
            </Col>
            <Col xs={{ size: 6, offset: 0 }} md={{ size: 3, offset: 1 }} lg={{ size: 5, offset: 0 }}>
              <a
                href="https://www.armastanaidata.ee/annetan/tervis-ja-toimetulek/tallinna-ja-harjumaa-autismiuhing-toetame-peredele-vajalikke-tugigruppe/"
                target="_blank"
                className="image"
              >
                <img
                  className="footer-image-box"
                  src={
                    gqlData.allWordpressWpMedia.edges.filter(({ node }) =>
                      node.source_url.includes('/2018/12/logo-2.png')
                    )[0].node.source_url
                  }
                />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

function renderLinks () {
  return (
    <div>
      <Row>
        <Link to="/pohikiri" className="link-footer">
          Põhikiri
        </Link>
      </Row>
      <Row>
        <Link to="/juhatus" className="link-footer">
          Juhatus
        </Link>
      </Row>
      <Row>
        <Link to="/uudised" className="link-footer">
          Uudised
        </Link>
      </Row>
      <Row>
        <Link to="/eesti-autismiuhing" className="link-footer">
          Eesti Autismiühing
        </Link>
      </Row>
    </div>
  )
}

function getFooterContent (gqlData) {
  return ReactHtmlParser(
    gqlData.allWordpressWpWidgets.edges.filter(
      widget => widget.node.title === 'Footer_' + languageService.getLanguage()
    )[0].node.text
  )
}

function getQuoute (footerContent) {
  return footerContent[0]
}

function getCompanyInfo (footerContent) {
  return (<span>
    {find(footerContent, {props: {id: 'company'}}).props.children[0]}
    <br/>
    {find(footerContent, {props: {id: 'reg'}}).props.children[0]}
    <br/>
    {find(footerContent, {props: {id: 'bank'}}).props.children[0]}
  </span>)
}

function getEmail (footerContent) {
  return find(footerContent, {props: {id: 'email'}})
}

function getPhone (footerContent) {
  return find(footerContent, {props: {id: 'phone'}})
}
